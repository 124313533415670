import React from 'react';
import styled from 'styled-components';
import {PageProps} from "gatsby";
import {TwoToneText} from "../components/TwoToneText";
import {Layout} from "../containers/Layout";
import {Media} from "../constants";
import {CTA} from "../components/CTA";

export default function Download(props: PageProps) {
    return <Layout {...props} meta={{title: 'Download'}}>
        <Container>
            <h2><TwoToneText w1={'Download the'} w2={'Test App'}/></h2>
            <CTA to={'https://play.google.com/apps/internaltest/4701490608540951458'} target="_blank" className={'download_link'}>Download Test app</CTA>

            <div className={'content'}>
                <h3>Getting a 404 Page?</h3>
                <p>If you get a page saying the app hasn't been found, we probably need to add you to the list of permitted tests users. Send us an email requesting access at <a href="mailto:support@unprn.com" target='_blank'>support@unprn.com</a>.</p>

                <h3>Feedback</h3>
                <p>Please send feedback about crashes and problems to <a href="mailto:support@unprn.com" target='_blank'>support@unprn.com</a>.</p>
                <p>Please send ideas and requests for features and improvements via <a href="https://feedback.unprn.com" target='_blank'>feedback.unprn.com</a>.</p>

                <h4>Thank you</h4>
            </div>
        </Container>
    </Layout>;
}


const Container = styled.div`
  min-height: 75vh;
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (${Media.laptop}) {
    width: 100%;
    max-width: 100rem;
  }

  h2 {
    text-align: center;
  }

  h3 {
    text-align: center;
  }

  .content {
    max-width: 50rem;
  }

  .auto_redirect {

  }

  .redirect_message {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 1rem;

    i {
      margin-right: 0.5rem;
    }
  }

  .download_container {
    text-align: center;
  }

  .download_link {
    margin: 2rem;

    button {
      padding: 2rem 3rem;
    }
  }

  .download_button {
    display: block;

    img {
      width: 300px;
    }
  }


  .image {
    margin: 0 auto;

    img {
      max-width: 100%;
    }
  }

  .howto {
    width: 100%;
    @media (${Media.laptop}) {
      margin-top: 2rem;
      padding: 0 2rem;
    }


    .p-inline-message {
      margin: 1rem 0;
    }

    .accordion {
    }

    .image {

    }

  }
`;
