import React, {ReactNode} from 'react';
import {Colors} from "../constants";


export type TwoToneTextProps = {
    w1: ReactNode;
    w2: ReactNode;
    reverse?: boolean;
};
export function TwoToneText({w1, w2, reverse}: TwoToneTextProps) {
    return <>
        <span style={{color: reverse ? Colors.Yellow : 'white'}}>{w1} </span>
        <span style={{color: reverse ? 'white' : Colors.Yellow}}>{w2}</span>
    </>;
}
